import './login.less';

(function() {
    angular.module('EntrakV5').controller('loginController', loginController);

    function loginController($scope, $rootScope, Service, Api, $state, $stateParams, $timeout, IS_LOCAL, REDIRECT_PORTAL_URL, REDIRECT_LANDLORD_URL, REDIRECT_DEMO_URL,  CLIENT_ID) {
        console.log('loginController');

        //$stateParams.landlord
        //$stateParams.lang
        if ($stateParams.lang){
            Service.setLangCode($stateParams.lang);
            $state.transitionTo('login', {
                landlord: $stateParams.landlord
            }, {
                notify: false,
                replace: true
            });
        }

        $scope.loginTo = $stateParams.landlord.toLowerCase();
        if ($scope.loginTo !== 'landlord')
            $scope.loginTo = null;
        // if ($scope.loginTo !== 'demo' && $scope.loginTo !== 'landlord')
        //     $scope.loginTo = null;
        // $scope.toDemo = $scope.loginTo === 'demo';
        $scope.isLoginPage = true;

        $scope.userNamePlaceHolder = Service.translate('login.userName');
        $scope.passwordPlaceHolder = Service.translate('login.password');
        $scope.validationCodePlaceHolder = Service.translate('login.validationCode');

        $scope.loginData = {
            username: "",
            pwd: "",
            showPwd: false,
            isForgotPwd: false,
            forgotSent: false,
            loading: false,
            sending: false,
            toLandlord: $scope.loginTo === 'landlord',
            captchaImg: null,
            captcha: ""
        }

        $scope.login = function(){
          var email = $scope.loginData.username.trim().toLowerCase();
          if (!Service.isEmail(email)){
            $scope.errMsg = Service.translate("error.invalidLogin");
            return;
          }

          var redirectUrl = $scope.loginData.toLandlord ? REDIRECT_LANDLORD_URL : REDIRECT_PORTAL_URL;
          redirectUrl = redirectUrl.replace("${langCode}", $rootScope.langCode);
        	$scope.errMsg = null;
          $scope.loginData.loading = true;
          Service.formPost("auth/signin", { password: $scope.loginData.pwd, email, tokens: 1 })
            .then(Service.parseJson)
            .then(res => {
              $scope.$apply(function(){
                if (res.errno) {
                  console.log("signin error:", res.errno, res.errmsg);
                  if (res.errno == 504 || res.errno == 406) {
                    $scope.errMsg = Service.translate("error.tooManyTry");
                  } else if (res.errno == 402) {
                    $scope.errMsg = Service.translate("error.invalidLogin");
                  } else if (res.errno == 505) {
                    $scope.errMsg = Service.translate("error.invalidPwd");
                  } else if (res.errno == 502) {
                    $scope.errMsg = Service.translate("error.generalLoginFail");
                    // ignore this for now, no tenant is using self-reg
                    // navigate(`/createPwd/${encodeURIComponent(email)}/${res.extra.session}/${res.extra.userpool}`);
                  } else {
                    $scope.errMsg = Service.translate("error.generalLoginFail");
                  }
                  $scope.loginData.loading = false;
                } else {
                  Api.initApolloClient("cognitoToken", res.extra.id_token);
                  Api.logSignIn();
                  Service.setCookie("et_method", "cognitoToken");
                  Service.setCookie("et_it", res.extra.id_token);
                  Service.setCookie("et_rt", res.extra.refresh_token);
                  setTimeout(function(){
                    if (IS_LOCAL)
                      redirectUrl = `${redirectUrl}?m=cognitoToken&it=${res.extra.id_token}&rt=${res.extra.refresh_token}`;
                    window.location = redirectUrl;
                  }, 300);
                }
              });
            })
            .catch(err => {
              $scope.$apply(function(){
                $scope.loginData.loading = false;
                console.error("login err", err);
                $scope.errMsg = Service.translate("error.generalLoginFail");
              });
            });
        }

        $scope.goMsLoginPage = function(){
          if ($scope.loginData.loading)
            return;
          // var site = $scope.loginData.toLandlord ? REDIRECT_LANDLORD_URL : REDIRECT_PORTAL_URL;
          // site = encodeURIComponent(site.replace("${langCode}", $rootScope.langCode));
          // window.location.href = `${MS_OAUTH_URL}&client_id=${CLIENT_ID}&state=${site}`;
          var codes = Service.generalCodeVerifierAndChallenge();
          if (Service.storageSave('codeVerifier', codes.verifier))
              window.location.href = Api.getRequestMSCodeURL($scope.loginData.toLandlord, codes.challenge);
        }

        $scope.showForgotPwd = function(show){
            $scope.loginData.isForgotPwd = show;
            $scope.loginData.captchaImg = null;
            $scope.loginData.captcha = "";
            $scope.errMsg = null;
            $scope.getCaptcha();
        }

        $scope.getCaptcha = function(){
          Service.formPost("auth/captcha")
            .then(Service.parseBlob)
            .then(res => {
              const fileReaderInstance = new FileReader();
              fileReaderInstance.readAsDataURL(res); 
              fileReaderInstance.onload = () => {
                $scope.$apply(function(){
                  $scope.loginData.captchaImg = fileReaderInstance.result;
                });
              }
            })
            .catch(e => console.error(e));
        }

        $scope.confirmForgotPwd = function(){
          $scope.loginData.sending = true;
          $scope.errMsg = null;
          Service.formPost("auth/forgotpwd", { email: $scope.loginData.username, captcha: $scope.loginData.captcha, page: "landlord" })
            .then(Service.parseJson)
            .then(r => {
              $scope.$apply(function(){
                if (r.errno){
                  $scope.getCaptcha();
                  if (r.errno == 401) {
                    $scope.errMsg = Service.translate("error.captchaFail");
                  } else if (r.errno == 403) {
                    $scope.errMsg = Service.translate("error.invalidEmail");
                  } else {
                    $scope.errMsg = Service.translate("error.contactAdmin");
                  }
                  console.log("forgetPwd error:", r.errno, r.errmsg);
                } else {
                  $scope.loginData.forgotSent = true;
                }
                $scope.loginData.sending = false;
              });
            })
            .catch(e => {
              $scope.$apply(function(){
                $scope.getCaptcha();
                $scope.loginData.sending = false;
                $scope.errMsg = Service.translate("err.contactAdmin")
              });
            });
        }

        $scope.goRegisterAccount = function(){
            $state.go("registration");
        }

        $scope.backToSignIn = function(){
            $scope.loginData.isForgotPwd = false;
            $scope.loginData.forgotSent = false;
        }

        $scope.$on('$destroy', function() {
            console.log("loginController destroy");
        });
    }
})();
